import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundDown'
})
export class RoundDownPipe implements PipeTransform {

    transform(value: number, digits: number = 0): number {
        return Math.floor(value * (10 ** digits)) / (10 ** digits);
    }

}
