import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import Hls from 'hls.js';
import Plyr from 'plyr';
import 'plyr-vr/dist/plyr-vr.min.js';

@Component({
	selector: 'app-dialog-video-player',
	templateUrl: 'dialog-video-player.component.html',
	providers: [DynamicDialogRef],
})
export class DialogVideoPlayerComponent implements AfterViewInit, OnChanges {
	public thumbnailLink: string;

	constructor(public dialogConfig: DynamicDialogConfig, private _dialogRef: DynamicDialogRef) {
		if (dialogConfig.data) {
			this.thumbnailLink = this.dialogConfig.data.thumbnailLink;
		}
	}

	ngAfterViewInit() {
		if (this.dialogConfig.data) {
			this.loadVideo();
		}
	}

	ngOnChanges() {
		if (this.dialogConfig.data) {
			this.loadVideo();
		}
	}

	private loadVideo() {
		const video = document.getElementById('videoStream') as HTMLMediaElement;
		const playerStream: any = new Plyr(video, {
			settings: ['captions', 'quality', 'speed', 'loop'],
			controls: [
				'play-large',
				'play',
				'progress',
				'current-time',
				'mute',
				'volume',
				'captions',
				'settings',
				'airplay',
				'fullscreen',
			],
		});

		const hls = new Hls();
		hls.loadSource(this.dialogConfig.data.sourceLink);
		hls.attachMedia(video);
		video.removeAttribute('poster');
	}
}
