import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'padLeft',
})
export class PadLeftPipe implements PipeTransform {
	transform(value: number): string {
		return value.toString().padStart(2, '0');
	}
}
