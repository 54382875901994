import { AfterViewInit, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import Hls from 'hls.js';
import Plyr from 'plyr';
import 'plyr-vr/dist/plyr-vr.min.js';

type PlyrVrParam = {
    projection: string
}
type PlyrVrType = {
    vr: ({}: PlyrVrParam) => void;
};
type PlyrType = Plyr & PlyrVrType;
@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
})
export class VideoPlayerComponent implements OnChanges, AfterViewInit, OnDestroy {
    @Input() sourceLink: string;
    @Input() is180Video: boolean;

    private playerStream: PlyrType;

    constructor() {}
    ngOnDestroy(): void {
        this.playerStream.destroy();
    }

    ngAfterViewInit(): void {
        if (this.is180Video) {
            this.load3dVideo()
        }
        else {
            this.load2dVideo()
        }
    }

    ngOnChanges(): void {
        if (this.is180Video) {
            this.load3dVideo()
        }
        else {
            this.load2dVideo()
        }
    }

    private load3dVideo() {
        const video = document.getElementById('video3dStream') as HTMLMediaElement;
        this.playerStream = new Plyr(video, {
            settings: ['captions', 'quality', 'speed', 'loop'],
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen']
        }) as PlyrType;

        const hls = new Hls();
        hls.loadSource(this.sourceLink);
        hls.attachMedia(video);

        this.playerStream.vr({ projection: '180' });
    }
    private load2dVideo() {
        const video = document.getElementById('video2dStream') as HTMLMediaElement;
        this.playerStream = new Plyr(video, {
            settings: ['captions', 'quality', 'speed', 'loop'],
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen']
        }) as PlyrType;

        const hls = new Hls();
        hls.loadSource(this.sourceLink);
        hls.attachMedia(video);
    }
}
